import React from 'react';
import { Select, TextInput } from '@mantine/core';
import { ProjectCategory, project_category } from './project-data';
import { useProjectData } from '../../../hooks/use-project-data';

const ProjectFilters = () => {
    const { setProjectName, setSelectedCategory, setSelectedYear } = useProjectData();

    return (
        <section className='flex w-full justify-center lg:py-10 py-6'>
            <div className='flex flex-col w-11/12 max-w-7xl'>
                <div className='text-gray-600 font-semibold text-3xl uppercase self-center mb-2'>Search</div>
                <div className='w-full lg:inline-flex lg:flex-row flex flex-col items-center justify-center gap-2'>
                    <TextInput
                        className='lg:w-4/12 w-full'
                        onKeyUp={e => setProjectName(e.currentTarget.value)}
                        placeholder='Project Name'
                    />
                    <Select
                        className='lg:w-fit w-full'
                        onChange={value => setSelectedCategory(value as ProjectCategory)}
                        data={['', ...project_category].map(category => {
                            if (!category) return { value: category, label: '❌ Clear Selection' };
                            return { value: category, label: category };
                        })}
                        placeholder='Project Category'
                    />
                    <Select
                        className='lg:w-fit w-full'
                        onChange={value => setSelectedYear(value || '')}
                        data={['', 2019, 2020, 2021, 2022].map(year => {
                            if (!year) return { value: year.toString(), label: '❌ Clear Selection' };
                            return {
                                value: year.toString(),
                                label: year.toString(),
                            };
                        })}
                        placeholder='Project Year'
                    />
                </div>
            </div>
        </section>
    );
};

export default ProjectFilters;
