import React from 'react';
import { Accordion } from '@mantine/core';
import { BsFillCalendar2DateFill } from 'react-icons/bs';
import { FaBuilding } from 'react-icons/fa';
import { FcRemoveImage } from 'react-icons/fc';
import { Project } from '../project-data';
import ProjectCategoryBadge from './project-category-badge';
import ProjectMiniGallery from './project-mini-gallery';
import ProjectStatusBadge from './project-status-badge';
import { useProjectData } from '../../../../hooks/use-project-data';

type ProjectListingsItemProps = {};

const ProjectListingsItem = ({
    name,
    company,
    year,
    status,
    categories,
    images,
}: ProjectListingsItemProps & Project) => {
    const { selected_accordion, handleAccordionChange } = useProjectData();
    const unique_id = `${name}_gallery`;

    return (
        <div className='border border-gray-200 rounded-lg lg:p-6 p-2'>
            <div className='text-xl font-semibold text-gray-700 mb-3'>{name}</div>
            <div className='inline-flex flex-col lg:flex-row gap-4 mb-2'>
                <ProjectStatusBadge status={status} />
                <div className='inline-flex items-center'>
                    <FaBuilding size='1.2rem' className='fill-gray-500 mr-0.5' />
                    <div className='text-sm text-gray-700 leading-none'>{company}</div>
                </div>
                <div className='inline-flex items-center'>
                    <BsFillCalendar2DateFill size='1.2rem' className='fill-gray-500 mr-1' />
                    <div className='text-sm text-gray-700 leading-none'>{year}</div>
                </div>
            </div>
            <div className='flex gap-x-2 mb-4'>
                {categories.map(category => (
                    <ProjectCategoryBadge key={`${name}_category_${category}`} category={category} />
                ))}
            </div>
            {images.length > 0 ? (
                <Accordion
                    id={unique_id}
                    variant='contained'
                    chevronPosition='left'
                    styles={{ item: { '&[data-active]': { backgroundColor: '#fff' } } }}
                    value={selected_accordion}
                    onChange={value => handleAccordionChange(value)}
                >
                    <Accordion.Item value={unique_id}>
                        <Accordion.Control>View Project Images</Accordion.Control>
                        <Accordion.Panel>
                            {selected_accordion === unique_id && (
                                <ProjectMiniGallery name={unique_id} images={images} />
                            )}
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
            ) : (
                <div className='w-full inline-flex items-center border border-gray-300 p-3 rounded'>
                    <FcRemoveImage className='mr-2' size='2.4rem' />
                    <span className='text-gray-500'>No Image Available</span>
                </div>
            )}
        </div>
    );
};

export default ProjectListingsItem;
