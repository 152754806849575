import React, { HTMLAttributes } from 'react';
import { Project, ProjectCategory, project_data } from '../components/pages/projects/project-data';

type ProjectContextData = {
    filtered_items: Project[];
    selected_accordion: string;
    handleAccordionChange: (accordion_id: string | null) => void;
    setProjectName: React.Dispatch<React.SetStateAction<string>>;
    setSelectedCategory: React.Dispatch<React.SetStateAction<ProjectCategory | ''>>;
    setSelectedYear: React.Dispatch<React.SetStateAction<string>>;
};

const ProjectContext = React.createContext<ProjectContextData>({
    filtered_items: [],
    selected_accordion: '',
    handleAccordionChange: () => {},
    setProjectName: () => {},
    setSelectedCategory: () => {},
    setSelectedYear: () => {},
});

export const useProjectData = () => {
    const context = React.useContext(ProjectContext);
    if (!context) {
        throw new Error('useProjectData must be used within ProjectContextProvider');
    }
    return context;
};

export const ProjectDataWrapper = ({ children }: HTMLAttributes<HTMLElement>) => {
    const [selected_accordion, setSelectedAccordion] = React.useState('');
    const [project_name, setProjectName] = React.useState('');
    const [selected_category, setSelectedCategory] = React.useState<ProjectCategory | ''>('');
    const [selected_year, setSelectedYear] = React.useState('');
    const [filtered_items, setFilteredItems] = React.useState<Project[]>([]);

    React.useEffect(() => {
        let current_items = project_data;
        if (selected_category) {
            current_items = current_items.filter(project => project.categories.includes(selected_category));
        }
        if (selected_year) {
            current_items = current_items.filter(project => project.year === Number(selected_year));
        }
        if (project_name) {
            current_items = current_items.filter(project => new RegExp(project_name, 'i').test(project.name));
        }
        setFilteredItems(current_items);
    }, [selected_category, selected_year, project_name]);

    const handleAccordionChange = (accordion_id: string | null) => {
        setSelectedAccordion(accordion_id && accordion_id !== selected_accordion ? accordion_id : '');
    };

    return (
        <ProjectContext.Provider
            value={{
                filtered_items,
                selected_accordion,
                handleAccordionChange,
                setProjectName,
                setSelectedCategory,
                setSelectedYear,
            }}
        >
            {children}
        </ProjectContext.Provider>
    );
};
