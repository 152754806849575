import React from 'react';
import classNames from 'classnames';
import { ProjectCategory } from '../project-data';

const getBadgeColor = (category: ProjectCategory) => {
    switch (category) {
        case 'Design & 3D Printing':
            return 'border-cyan-300 bg-cyan-50 text-cyan-600';
        case 'IOT Monitoring Solutions':
            return 'border-purple-300 bg-purple-50 text-purple-600';
        case 'Jig & Panel Fabrication':
            return 'border-amber-300 bg-amber-50 text-amber-600';
        case 'On-site Engineering & Manpower':
            return 'border-purple-300 bg-purple-50 text-purple-600';
        case 'PLC & SCADA System Integration':
            return 'border-red-300 bg-red-50 text-red-600';
        case 'Wireless Sensor Technology':
            return 'border-teal-300 bg-teal-50 text-teal-600';
        case 'Software & AI Development':
        default:
            return 'border-pink-300 bg-opink-50 text-pink-500';
    }
};

type ProjectCategoryBadgeProps = {
    category: ProjectCategory;
};

const ProjectCategoryBadge = ({ category }: ProjectCategoryBadgeProps) => {
    return (
        <span className={classNames('font-semibold text-xs border rounded px-2 py-1', getBadgeColor(category))}>
            {category}
        </span>
    );
};

export default ProjectCategoryBadge;
