import React, { ReactNode } from 'react';
import classNames from 'classnames';
import * as css from './title-image.module.css';

interface TitleImageProps {
    children: ReactNode;
    title: string;
}

const TitleImage = ({ children, title }: TitleImageProps) => {
    return (
        <section className='relative flex flex-col w-full'>
            <div className='relative flex flex-col justify-center items-center w-full h-screen lg:h-96'>
                <div
                    className={classNames(
                        css.hero_image,
                        'absolute flex flex-col w-11/12 max-w-7xl justify-center bg-white mix-blend-lighten px-4 py-3 lg:py-6 rounded-lg shadow z-10'
                    )}
                >
                    <h1 className='text-6xl lg:text-9xl text-center font-bold uppercase'>{title}</h1>
                </div>
                {children}
            </div>
        </section>
    );
};

export default TitleImage;
