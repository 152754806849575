import React, { ReactNode, useCallback } from 'react';
import classNames from 'classnames';
import useEmblaCarousel from 'embla-carousel-react';

type ProjectMiniGalleryProps = {
    name: string;
    images: ReactNode[];
};

const ProjectMiniGallery = ({ name, images }: ProjectMiniGalleryProps) => {
    const [selected_index, setSelectedIndex] = React.useState(0);
    const [main_ref, main_api] = useEmblaCarousel({});
    const [thumbnail_ref, thumbnail_api] = useEmblaCarousel({ containScroll: 'keepSnaps', dragFree: true });

    const onThumbnailClick = React.useCallback(
        (index: number) => {
            if (!main_api || !thumbnail_api) return;
            if (thumbnail_api.clickAllowed()) main_api.scrollTo(index);
        },
        [main_api, thumbnail_api]
    );

    const onSelect = useCallback(() => {
        if (!main_api || !thumbnail_api) return;
        setSelectedIndex(main_api.selectedScrollSnap());
        thumbnail_api.scrollTo(main_api.selectedScrollSnap());
    }, [main_api, thumbnail_api, setSelectedIndex]);

    React.useEffect(() => {
        if (!main_api) return;
        onSelect();
        main_api.on('select', onSelect);
        main_api.on('reInit', onSelect);
    }, [main_api, onSelect]);

    React.useEffect(() => {
        main_api?.reInit();
        thumbnail_api?.reInit();

        return () => {
            main_api?.destroy();
            thumbnail_api?.destroy();
        };
    }, [main_api, thumbnail_api]);

    return (
        <React.Fragment key={`mini_gallery_${name}`}>
            <div className=''>
                <div ref={main_ref} className='overflow-hidden w-full lg:h-1/2 mb-4'>
                    <div className='flex flex-row h-auto'>
                        {images.map((image, index) => {
                            return (
                                <div key={`${name}-slide_main_${index}`} className='w-full grow-0 shrink-0 basis-full'>
                                    {image}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div ref={thumbnail_ref} className='overflow-hidden'>
                    <div>
                        <div className='flex lg:h-52'>
                            {images.map((image, index) => {
                                return (
                                    <div
                                        key={`${name}-slide_thumbnail_${index}`}
                                        className={classNames('grow-0 shrink-0 basis-2/12 relative mr-4 opacity-50', {
                                            'opacity-100': selected_index === index,
                                        })}
                                    >
                                        <button onClick={() => onThumbnailClick(index)}>{image}</button>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ProjectMiniGallery;
