import React, { ReactNode } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export const project_category = [
    'Design & 3D Printing',
    'Software & AI Development',
    'IOT Monitoring Solutions',
    'Jig & Panel Fabrication',
    'On-site Engineering & Manpower',
    'Wireless Sensor Technology',
    'PLC & SCADA System Integration',
] as const;

export type ProjectCategory = typeof project_category[number];
export type ProjectStatus = 'completed' | 'ongoing';
export type Project = {
    name: string;
    company: string;
    status: ProjectStatus;
    year: number;
    categories: ProjectCategory[];
    images: ReactNode[];
};

export const project_data: Project[] = [
    {
        name: 'Refurbishment of Refractory Work for Sibu Incinerator Plant',
        company: 'One Medicare Sdn Bhd',
        categories: ['PLC & SCADA System Integration'],
        year: 2019,
        status: 'completed',
        images: [
            <StaticImage src='../../../images/projects/hospital-sibu/photo1.jpg' alt='incinerator front view' />,
            <StaticImage src='../../../images/projects/hospital-sibu/photo2.jpg' alt='incinerator opened chamber' />,
            <StaticImage src='../../../images/projects/hospital-sibu/photo3.jpg' alt='incinerator control panel' />,
            <StaticImage
                src='../../../images/projects/hospital-sibu/photo4.jpg'
                alt='incinerator control panel display panel'
            />,
        ],
    },
    {
        name: 'IoT Vibration Sensor at PGB Utilities Kerteh',
        company: 'Petronas Gas Berhad',
        categories: ['Wireless Sensor Technology'],
        year: 2020,
        status: 'completed',
        images: [
            <StaticImage
                src='../../../images/projects/iot-sensor/photo1.jpg'
                objectFit='contain'
                alt='IOT module on blue machine'
            />,
            <StaticImage
                src='../../../images/projects/iot-sensor/photo2.jpg'
                objectFit='contain'
                alt='IOT module on gray machine'
            />,
            <StaticImage
                src='../../../images/projects/iot-sensor/photo3.jpg'
                objectFit='contain'
                alt='network access point for IOT'
            />,
            <StaticImage
                src='../../../images/projects/iot-sensor/photo4.jpg'
                objectFit='contain'
                alt='IOT device switch'
            />,
            <StaticImage
                src='../../../images/projects/iot-sensor/photo5.jpg'
                objectFit='contain'
                alt='IOT device mounted underneath machine'
            />,
            <StaticImage
                src='../../../images/projects/iot-sensor/photo6.jpg'
                objectFit='contain'
                alt='IOT device mounted under column flange'
            />,
            <StaticImage
                src='../../../images/projects/iot-sensor/photo7.jpg'
                objectFit='contain'
                alt='IOT device on top of machine'
            />,
            <StaticImage
                src='../../../images/projects/iot-sensor/photo8.jpg'
                objectFit='contain'
                alt='IOT device attached on the side of machine'
            />,
            <StaticImage
                src='../../../images/projects/iot-sensor/photo9.jpg'
                objectFit='contain'
                alt='IOT monitoring mobile app'
            />,
        ],
    },
    {
        name: 'EPCI of Hydraulic Actuated Bypass Valve at MLL 2A',
        company: 'Petronas Gas Berhad',
        categories: ['PLC & SCADA System Integration'],
        year: 2020,
        status: 'completed',
        images: [],
    },
    {
        name: 'LIMITORQUE MOV Board Design & Fabrication',
        company: 'Malaysia LNG Sdn. Bhd.',
        categories: ['PLC & SCADA System Integration', 'Jig & Panel Fabrication'],
        year: 2020,
        status: 'completed',
        images: [
            <StaticImage src='../../../images/projects/mlng/photo1.jpg' alt='3d printed mov casing top' />,
            <StaticImage src='../../../images/projects/mlng/photo2.jpg' alt='3d printed mov casing front' />,
            <StaticImage src='../../../images/projects/mlng/photo3.jpg' alt='mov casing 3d model' />,
        ],
    },
    {
        name: 'Customize of Zone 1 battery chargeable unit (FDB 0001)',
        company: 'Petronas Research Sdn. Bhd.',
        categories: ['Design & 3D Printing'],
        year: 2020,
        status: 'completed',
        images: [
            <StaticImage src='../../../images/projects/battery-pack/photo1.jpg' alt='battery pack front view' />,
            <StaticImage
                src='../../../images/projects/battery-pack/photo2.jpg'
                alt='handover meeting for battery pack'
            />,
            <StaticImage src='../../../images/projects/battery-pack/photo3.jpg' alt='battery pack display indicator' />,
        ],
    },
    {
        name: 'EPCC of TTLR Sulphur Facility Upgrading at PDB Melaka Fuel Terminal',
        company: 'Petronas Dagangan Berhad',
        categories: ['On-site Engineering & Manpower'],
        year: 2020,
        status: 'completed',
        images: [
            <StaticImage src='../../../images/projects/pdb/photo1.jpg' alt='petronas gas berhad plant' />,
            <StaticImage src='../../../images/projects/pdb/photo2.jpg' alt='workers unrolling wires' />,
            <StaticImage src='../../../images/projects/pdb/photo3.jpg' alt='LCD panel of control device' />,
            <StaticImage src='../../../images/projects/pdb/photo4.jpg' alt='workers doing assembly work' />,
            <StaticImage src='../../../images/projects/pdb/photo5.jpg' alt='workers viewing LCD panel' />,
        ],
    },
    {
        name: 'Pump House Remote Monitoring System',
        company: 'Indah Water Konsortium',
        categories: ['IOT Monitoring Solutions'],
        year: 2021,
        status: 'completed',
        images: [
            <StaticImage src='../../../images/projects/iwk/photo1.jpg' alt='indah water panel circuit' />,
            <StaticImage src='../../../images/projects/iwk/photo2.jpg' alt='indah water power circuitry' />,
            <StaticImage src='../../../images/projects/iwk/photo3.jpg' alt='indah water control panel closed' />,
            <StaticImage src='../../../images/projects/iwk/photo4.jpg' alt='indah water control panel open' />,
            <StaticImage src='../../../images/projects/iwk/photo5.jpg' alt='indah water IOT network connection' />,
            <StaticImage src='../../../images/projects/iwk/photo6.jpg' alt='indah water full circuit with IOT' />,
        ],
    },
    {
        name: 'Cathodic Protection Monitoring System',
        company: 'Petronas Gas Berhad',
        categories: ['IOT Monitoring Solutions', 'Software & AI Development'],
        year: 2021,
        status: 'completed',
        images: [],
    },
    {
        name: 'Solar Energy Monitoring System',
        company: 'NEFIN Limited',
        categories: ['IOT Monitoring Solutions'],
        year: 2021,
        status: 'completed',
        images: [],
    },
    {
        name: 'Durability Tester Jig',
        company: 'KL Analytical Sdn Bhd',
        categories: ['Jig & Panel Fabrication'],
        year: 2021,
        status: 'completed',
        images: [
            <StaticImage
                src='../../../images/projects/durability-tester/photo1.jpg'
                alt='front view durability tester'
            />,
            <StaticImage
                src='../../../images/projects/durability-tester/photo2.jpg'
                alt='isometry view durability tester'
            />,
            <StaticImage
                src='../../../images/projects/durability-tester/photo3.jpg'
                alt='specimen mounted on durability tester'
            />,
            <StaticImage
                src='../../../images/projects/durability-tester/photo4.jpg'
                alt='bottom mounting of specimen'
            />,
            <StaticImage
                src='../../../images/projects/durability-tester/photo5.jpg'
                alt='full instrument with specimen'
            />,
            <StaticImage src='../../../images/projects/durability-tester/photo6.jpg' alt='specimen mounting bracket' />,
        ],
    },
    {
        name: 'Steam Power Plant System Training Skid',
        company: 'Kisumu National Polytechnic',
        categories: ['On-site Engineering & Manpower'],
        year: 2021,
        status: 'completed',
        images: [
            <StaticImage src='../../../images/projects/didaktik/photo1.jpg' alt='training skid platform side' />,
            <StaticImage src='../../../images/projects/didaktik/photo2.jpg' alt='training skid platform front' />,
            <StaticImage src='../../../images/projects/didaktik/photo3.jpg' alt='workers inspecting training skid' />,
            <StaticImage src='../../../images/projects/didaktik/photo4.jpg' alt='digital control panel' />,
            <StaticImage src='../../../images/projects/didaktik/photo5.jpg' alt='training skid commisiioning' />,
            <StaticImage src='../../../images/projects/didaktik/photo6.jpg' alt='steam control panel display' />,
        ],
    },
    {
        name: 'Inline USB Counting System',
        company: 'Sime Darby Research Sdn. Bhd.',
        categories: ['Software & AI Development'],
        year: 2022,
        status: 'completed',
        images: [],
    },
    {
        name: 'Kelulut Monitoring System',
        company: 'Universiti Malaysia Terengganu',
        categories: ['Wireless Sensor Technology'],
        year: 2022,
        status: 'completed',
        images: [],
    },
    {
        name: 'IoT Panel Gateway Fabrication',
        company: 'Bosch Rexroth Malaysia Sdn. Bhd.',
        categories: ['Jig & Panel Fabrication'],
        year: 2021,
        status: 'completed',
        images: [
            <StaticImage src='../../../images/projects/bosch/photo1.jpg' alt='mounted iot panel' />,
            <StaticImage src='../../../images/projects/bosch/photo2.jpg' alt='iot control panel' />,
            <StaticImage
                src='../../../images/projects/bosch/photo3.jpg'
                alt='cytro pack connected to iot control panel'
            />,
            <StaticImage src='../../../images/projects/bosch/photo4.jpg' alt='iot control panel different angle' />,
        ],
    },
    {
        name: 'Fabrication of Saloon Jigs & Load Cell Jigs',
        company: 'Hyundai Rotem Malaysia Sdn. Bhd.',
        categories: ['Jig & Panel Fabrication'],
        year: 2022,
        status: 'completed',
        images: [],
    },
];
