import React from 'react';
import { useProjectData } from '../../../../hooks/use-project-data';
import ProjectListingsItem from './project-listings-item';

const ProjectListings = () => {
    const { filtered_items } = useProjectData();
    return (
        <section className='flex w-full justify-center mb-20'>
            <div className='flex flex-col gap-y-6 w-11/12 max-w-7xl'>
                {filtered_items.map(project => (
                    <ProjectListingsItem key={`project_listing_item${project.name}`} {...project} />
                ))}
            </div>
        </section>
    );
};

export default ProjectListings;
