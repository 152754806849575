import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import PageHead from '../components/layout/page-head';
import TitleImage from '../components/layout/title-image';
import ProjectFilters from '../components/pages/projects/project-filters';
import ProjectListings from '../components/pages/projects/project-listings';
import { ProjectDataWrapper } from '../hooks/use-project-data';

const Projects = () => {
    return (
        <Layout>
            <ProjectDataWrapper>
                <TitleImage title='Our Projects'>
                    <StaticImage
                        className='absolute w-full h-full z-0'
                        src='../images/projects/text.png'
                        alt='worker inspecting large machine'
                    />
                </TitleImage>
                <ProjectFilters />
                <ProjectListings />
            </ProjectDataWrapper>
        </Layout>
    );
};

export const Head = () => (
    <PageHead
        title='Projects'
        description='"Discover the difference with Pacific Centric Sdn Bhd. Explore our portfolio of successful projects in system integration, custom software development, AI-powered solutions, industrial automation, and product design."'
    />
);

export default Projects;
