import React from 'react';
import classNames from 'classnames';
import { BsFillCheckCircleFill, BsHourglassSplit } from 'react-icons/bs';
import { ProjectStatus } from '../project-data';

type ProjectStatusBadgeProps = {
    status: ProjectStatus;
};

const ProjectStatusBadge = ({ status }: ProjectStatusBadgeProps) => {
    return (
        <span
            className={classNames('inline-flex items-center border p-1 rounded', {
                'border-green-500': status === 'completed',
                'border-blue-500': status === 'ongoing',
            })}
        >
            {status === 'completed' ? (
                <BsFillCheckCircleFill className='fill-green-700 mr-1' />
            ) : (
                <BsHourglassSplit className='mr-1' />
            )}
            <div
                className={classNames('text-xs font-semibold capitalize', {
                    'text-green-700': status === 'completed',
                    'text-blue-700': status === 'ongoing',
                })}
            >
                {status}
            </div>
        </span>
    );
};

export default ProjectStatusBadge;
